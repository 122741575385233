import * as fancy from '@fancyapps/ui'
const { Fancybox } = fancy
import '@fancyapps/ui/dist/fancybox/fancybox.css'

export default (inputs) => {
  if (Array.isArray(inputs)) {
    inputs.forEach((e) => {
      Fancybox.bind(e)
    })
  } else {
    Fancybox.bind(inputs)
  }
}
